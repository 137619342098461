import * as React from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import UserAvatar from "../../../assets/images/web_rating/user.png";
import { DatePicker } from "rsuite";
import "./client-connect.css";

export default function ClientConnectDirector({
  value,
  onChange,
  leadershipUser,
  clientConnect,
  isVisitInformation,
  selectedTimeOut = null,
  setSelectedTimeOut,
  isSurveyCompetition = false,
  updateContactDetails,
  contactDetail,
  contactDetailErrors,
  competitionInfoText,
}) {
  return (
    <Paper elevation={3} className="mt-2">
      <Card sx={{ maxWidth: 345 }}>
        {leadershipUser ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              Message from Leadership
            </Typography>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  src={leadershipUser?.image}
                  variant="circular"
                  style={{ width: 50, height: 50 }}
                  onError={(e) => {
                    e.target.src = UserAvatar;
                  }}
                />
              }
              title={leadershipUser?.name}
              subheader={
                <>
                  <Typography variant="body2" color="textPrimary">
                    {leadershipUser?.companyName}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {leadershipUser?.email}
                  </Typography>
                </>
              }
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Hi,{clientConnect?.username}
              </Typography>
              {leadershipUser?.message ? (
                ReactHtmlParser(leadershipUser.message)
              ) : (
                <>
                  <Typography variant="body2">
                    I am constantly seeking ways to better our offerings for our
                    clientele. Are there aspects we could better in your
                    opinion?
                  </Typography>
                  <Typography variant="body2">
                    On another note, if there's a specific topic you'd like our
                    team to address, don't hesitate to share. I'm not just here
                    to listen, but to engage and act upon your concerns or
                    ideas.
                  </Typography>
                </>
              )}
            </CardContent>
            <CardActions>
              <TextField
                minRows={2}
                multiline
                value={value}
                onChange={onChange}
                size="medium"
                placeholder="Your message here"
                variant="outlined"
              />
            </CardActions>
          </>
        ) : null}
        {isVisitInformation ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              Visit Information
            </Typography>
            <CardContent>
              <label>Visit Time Out *:</label>
              <DatePicker
                format="HH:mm"
                ranges={[]}
                value={selectedTimeOut}
                onChange={(time) => setSelectedTimeOut(time)}
                className="form-control p-0"
                block
                style={{
                  color: "darkgray",
                  fontSize: "14px",
                }}
                placement={
                  leadershipUser && isVisitInformation
                    ? "topStart"
                    : "bottomStart"
                }
              />
            </CardContent>
          </>
        ) : null}

        {/* Show Survey Competition Information */}
        {isSurveyCompetition ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              Contact Information
            </Typography>
            <CardContent>
              {competitionInfoText ? (
                <div
                  style={{ marginBottom: "15px" }}
                  className="competition-info"
                >
                  {ReactHtmlParser(competitionInfoText || "")}
                </div>
              ) : (
                <div style={{ marginBottom: "15px" }}>
                  To provide enhanced assistance, we kindly ask for your
                  details, including your name, email address, and contact
                  number. This information will enable us to respond promptly
                  and contact you.
                </div>
              )}

              <label>Name*:</label>
              <TextField
                placeholder="Enter your name"
                value={contactDetail?.name}
                onChange={(e) => updateContactDetails(e, "name")}
                size="small"
                variant="outlined"
              />
              {contactDetailErrors?.name.error ? (
                <p className="text-danger pt-1">
                  {contactDetailErrors?.name?.message}
                </p>
              ) : null}
              <label className="mt-1">
                Email<span style={{ color: "red" }}>*</span>:
              </label>
              <TextField
                placeholder="Enter your email"
                value={contactDetail?.email}
                onChange={(e) => updateContactDetails(e, "email")}
                size="small"
                variant="outlined"
              />
              {contactDetailErrors?.email.error ? (
                <p className="text-danger pt-1">
                  {contactDetailErrors?.email?.message}
                </p>
              ) : null}

              <label className="mt-1">
                Contact No
                {!contactDetailErrors?.emailValid?.error ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
                :
              </label>
              <div
                className="mt-1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#fff",
                  border: "1px solid #a0a0a0",
                  borderRadius: "4px",
                  paddingLeft: "14px !important",
                  overflow: "hidden",
                  fontFamily: "sans-serif",
                }}
              >
                <span className="prefix pl-2">+44</span>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={contactDetail?.phone}
                  type="number"
                  onChange={(e) => updateContactDetails(e, "phone")}
                  className="contact-number pl-2"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    style: { paddingLeft: 0 },
                  }}
                />
              </div>
              {contactDetailErrors?.phone.error ? (
                <p className="text-danger pt-1">
                  {contactDetailErrors?.phone?.message}
                </p>
              ) : null}
              {contactDetailErrors?.emailOrPhone.error ? (
                <p className="text-danger pt-1">
                  {contactDetailErrors?.emailOrPhone?.message}
                </p>
              ) : null}
            </CardContent>
          </>
        ) : (
          ""
        )}
      </Card>
    </Paper>
  );
}
