import { TextField, makeStyles } from "@material-ui/core";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import UserAvatar from "../../../assets/images/web_rating/user.png";
import { DatePicker } from "rsuite";

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    borderBlockColor: theme.palette.primary.light,
  },
  input1: {
    borderColor: theme.palette.primary.light,
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

export default function ClientConnectDirector({
  value,
  onChange,
  leadershipUser,
  clientConnect,
  isVisitInformation,
  isSurveyCompetition = false,
  selectedTimeOut = null,
  setSelectedTimeOut,
  updateContactDetails,
  contactDetail,
  contactDetailErrors,
  competitionInfoText,
}) {
  const classes = useStyles();

  return (
    <div className="pb-3">
      {leadershipUser ? (
        <>
          <div className="text-center h3 mb-5 pt-2">
            Message from Leadership
          </div>

          <div className="h5 mb-4">Hi, {clientConnect?.username}</div>

          {leadershipUser?.message ? (
            ReactHtmlParser(leadershipUser.message)
          ) : (
            <>
              <div style={{ lineHeight: 2, fontSize: 21 }}>
                I am constantly seeking ways to better our offerings for our
                clientele. Are there aspects we could better in your opinion?
              </div>

              <div style={{ lineHeight: 2, fontSize: 21 }}>
                On another note, if there's a specific topic you'd like our team
                to address, don't hesitate to share. I'm not just here to
                listen, but to engage and act upon your concerns or ideas.
              </div>
            </>
          )}

          <div className="mt-5 d-flex align-items-center">
            <div style={{ borderRadius: "50%", overflow: "hidden" }}>
              <img
                width="65px"
                src={leadershipUser.image}
                onError={(e) => {
                  e.target.src = UserAvatar;
                }}
                alt="user"
                style={{ objectFit: "contain" }}
              />
            </div>

            <div className="ml-4">
              <div className="font-weight-bold">{leadershipUser?.name}</div>
              <div>{leadershipUser?.companyName}</div>
              <div className="small">{leadershipUser?.email}</div>
            </div>
          </div>

          <div className="mt-5">
            <TextField
              rows={4}
              multiline
              value={value}
              onChange={onChange}
              size="medium"
              placeholder="Your message here"
              className={classes.textfield}
              variant="outlined"
              InputProps={{ classes: { input: classes.input1 } }}
            />
          </div>
        </>
      ) : null}
      {isVisitInformation ? (
        <>
          <div className="text-center h4 mb-3 pt-4">Visit Information</div>
          <label>Visit Time Out *:</label>

          <DatePicker
            format="HH:mm"
            ranges={[]}
            value={selectedTimeOut}
            onChange={(time) => setSelectedTimeOut(time)}
            className="form-control p-0"
            block
            style={{
              color: "darkgray",
              fontSize: "14px",
            }}
          />
        </>
      ) : null}

      {/* Show Survey Competition Information */}
      {isSurveyCompetition ? (
        <>
          <div className="text-center h4 mb-3 pt-4">Contact Information</div>
          {competitionInfoText ? (
            <div style={{ fontSize: "1rem", color: "black !important" }}>
              {ReactHtmlParser(competitionInfoText || "")}
            </div>
          ) : (
            <div style={{ fontSize: "1rem", color: "black !important" }}>
              To provide enhanced assistance, we kindly ask for your details,
              including your name, email address, and contact number. This
              information will enable us to respond promptly and contact you.
            </div>
          )}

          <div className="mt-4">
            <h5 className="font-weight-normal">Name*</h5>
            <div className="mt-1">
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter your name"
                value={contactDetail?.name}
                onChange={(e) => updateContactDetails(e, "name")}
              />
            </div>
            {contactDetailErrors?.name.error ? (
              <p className="text-danger pt-1">
                {contactDetailErrors?.name?.message}
              </p>
            ) : null}
          </div>

          <div className="mt-4">
            <h5 className="font-weight-normal">
              Email<span style={{ color: "red" }}>*</span>
            </h5>
            <div className="mt-1">
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter your email"
                value={contactDetail?.email}
                onChange={(e) => updateContactDetails(e, "email")}
              />
            </div>
            {contactDetailErrors?.email.error ? (
              <p className="text-danger pt-1">
                {contactDetailErrors?.email?.message}
              </p>
            ) : null}
          </div>

          <div className="mt-4">
            <h5 className="font-weight-normal">
              Contact No
              {!contactDetailErrors?.emailValid?.error ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )}
            </h5>
            <div className="mt-1 input-box">
              <span className="prefix">+44</span>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={contactDetail?.phone}
                type="number"
                onChange={(e) => updateContactDetails(e, "phone")}
                className="contact-number pl-2"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            {contactDetailErrors?.phone.error ? (
              <p className="text-danger pt-1">
                {contactDetailErrors?.phone?.message}
              </p>
            ) : null}
          </div>

          <div className="mt-4">
            {contactDetailErrors?.emailOrPhone.error ? (
              <p className="text-danger pt-1">
                {contactDetailErrors?.emailOrPhone?.message}
              </p>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}
