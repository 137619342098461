import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    paddingRight: "1rem !important",
    paddingLeft: "1rem !important",
  },
  container: {
    padding: "0.6rem",
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
  },
  headingBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 20px",
  },

  qTitle: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  actionContainer: {
    display: "flex",
    margin: "auto",
    gap: 20,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginTop: "15px",
    },
  },
  action: {
    padding: "0.5rem",
    border: "1px solid grey",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    minWidth: 80,
    justifyContent: "center",
    color: "grey",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      minWidth: 55,
      padding: "0.2rem !important",
    },
  },
  actionTrue: {
    border: "1px solid green",
    background: "green",
  },
  actionFalse: {
    border: "1px solid red",
    background: "red",
  },
  actionTextActive: {
    color: "white",
  },
  actionText: {
    color: "grey",
    margin: 0,
  },

  divider: {
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
